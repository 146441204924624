<template>
  <VCard class="px-2 py-2 mb-2">
    <VRow class="align-center px-2" no-gutters justify="space-between">
      <VCol cols="auto" class="text-left">
        <h3 class="text-h4">{{ $t('addresses.adressesTitle') }}</h3>
      </VCol>
      <VCol cols="auto" class="text-right">
        <VBtn
          depressed
          @click="createDialog = true"
          class="success-bg text-white text-transform-none br-10"
        >
          <VIcon :left="!$vuetify.breakpoint.xs">mdi-plus</VIcon>
          <div v-if="!$vuetify.breakpoint.xs">
            {{ $t('addresses.createAdressBtn') }}
          </div>
        </VBtn>
      </VCol>
    </VRow>
    <VDivider class="mt-3" />
    <div>
      <!-- start -->
      <v-simple-table class="my-table" :dense="!isLarge">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="icon text-center"><VIcon dense>mdi-cogs</VIcon></th>
              <th class="icon text-center"><VIcon dense>mdi-account-box</VIcon></th>
              <th class="text-left">
                <div class="header">
                  <span>{{$t('table.name')}}</span>
                  <VTextField dense hide-details solo v-model="filter.name" clearable />
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                  <span>{{$t('table.surname')}}</span>
                  <VTextField dense hide-details solo v-model="filter.surname" clearable />
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                  <span>{{$t('table.phone')}}</span>
                  <VTextField
                    v-on:keypress="onlyNumber($event)"
                    dense
                    hide-details
                    solo
                    v-model="filter.phone"
                    clearable
                  />
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                  <span>{{$t('table.email')}}</span>
                  <VTextField dense hide-details solo v-model="filter.email" clearable />
                </div>
              </th>
              <th class="text-left sm">
                <div class="header">
                  <span>{{$t('table.city')}}</span>
                  <VTextField dense hide-details solo v-model="filter.name_city" clearable />
                </div>
              </th>
              <th class="text-left sm">
                <div class="header">
                  <span>{{$t('table.department')}}</span>
                  <VTextField dense hide-details solo v-model="filter.name_warehouse" clearable />
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <AddressRow
              v-for="(address, index) in addresses"
              :key="address.uuid"
              :address="address"
              :index="index"
              @toggleRow="toggleRow"
              :class="{ 'active-row': activeRow == address.uuid }"
            />
          </tbody>
        </template>
      </v-simple-table>
      <v-progress-linear v-if="isLoadingFilter" indeterminate color="primary"></v-progress-linear>
      <h3 v-if="!addresses.length" class="text-center">{{$t('table.noData')}}</h3>
      <!-- <div class="text-center mt-3">
        <VBtn
          depressed
          color="blue"
          outlined
          dark
          v-if="!loadMoreDisabled"
          @click="nextPage"
          class="br-10"
        >
          Завантажити більше
        </VBtn>
      </div> -->
      <div
        v-observer="{ nextPage: nextPage }"
        class="observer"
        v-if="!loadMoreDisabled && !loading"
      ></div>
    </div>
    <CreateAddressDialog
      v-if="createDialog"
      :visible="createDialog"
      @close="createDialog = false"
    />
    <ViewAddressDialog
      v-if="visible && selectedAddress"
      :address="selectedAddress"
      :visible="visible"
      @close="
        visible = false;
        selectedAddress = null;
      "
    />
    <DeleteAddressDialog
      :visible="deleteDialog"
      @close="deleteDialog = false"
      :uuid="uuidToDelete"
      @delete="onDeleteAddress"
    />
  </VCard>
</template>

<script>
import language from '../../mixins/language';
import notifications from '../../mixins/notifications';
import addressService from '../../services/request/address/addressService';
import loader from '../../mixins/loader';
import CreateAddressDialog from './CreateAddressDialog.vue';
import EventBus from '../../events/EventBus';
import ViewAddressDialog from './EditAddressDialog.vue';
import DeleteAddressDialog from '../dialog/ConfirmDeleteDialog.vue';
import AddressRow from './AddressRow.vue';
import { uniqueByUUID, onlyNumber } from '../../mixins/helpers';
import SizeUi from '../../mixins/SizeUi';

export default {
  name: 'AddressesComponent',
  mixins: [language, notifications, loader, SizeUi],
  components: {
    CreateAddressDialog,
    ViewAddressDialog,
    DeleteAddressDialog,
    AddressRow,
  },
  data: () => ({
    isLoadingFilter: false,
    addresses: [],
    createDialog: false,
    page: 1,
    perPageList: [5, 10, 15],
    firstNumber: 0,
    secondNumber: 0,
    total: 4,
    visible: false,
    selectedAddress: false,
    uuidToDelete: '',
    deleteDialog: false,
    filter: {
      name: '',
      surname: '',
      phone: '',
      email: '',
    },
    loadMoreDisabled: false,
    activeRow: '',
    loading: false,
    inputFilter: {},
  }),
  watch: {
    page(e) {
      if (this.perPage * this.page >= 200 && this.perPage * this.page <= 200 + this.perPage) {
        this.pageBeforeLimit = e;
        console.log(e);
      }
    },
    filter: {
      deep: true,
      async handler() {
        const params = {
          uselike: true,
        };
        Object.keys(this.filter).forEach(key => {
          if (this.filter[key]) {
            params[key] = this.filter[key];
          }
        });
        this.inputFilter = params;
        // console.log(this.inputFilter)
        // orderDialog(this.filter)
        if (params.phone) {
          params.phone = params.phone.replace(/[^+\d]/g, '');
        }
        console.log(params);
        this.page = 1;
        await this.getAddresses(params, false, true);
      },
    },
    async perPage() {
      this.page = 1;
      await this.getAddresses();
    },
  },
  computed: {
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },
    perPage() {
      return Math.ceil((window.innerHeight - 150) / this.rowHeight);
    },
    largeLimit() {
      const pageLimit = this.perPage * this.page;
      if (pageLimit >= 200) {
        this.page = this.pageBeforeLimit - 1;
        return 200;
      }
      return this.perPage * this.page;
    },
    nextPageDisabled() {
      return this.addresses.length < this.perPage;
    },
  },
  methods: {
    onlyNumber,
    toggleRow(e) {
      this.activeRow = e;
    },
    async onDeleteAddress(uuid) {
      try {
        this.deleteDialog = false;
        this.setLoading(true);
        await addressService.deleteAddress(uuid);
        this.setInfoNotification('Адрес було успішно видалено!');
        this.$emit('address-deleted');
        this.uuidToDelete = '';
        this.deleteDialog = false;
        this.setLoading(false);
      } catch (e) {
        this.setLoading(false);
        this.setErrorNotification(e);
      }
    },
    selectAddress(address) {
      this.selectedAddress = address;
      this.visible = true;
    },
    deleteAddress(uuid) {
      this.uuidToDelete = uuid;
      this.deleteDialog = true;
    },
    getLetters(address) {
      let letters = '';
      if (address.name) {
        letters += address.name.charAt(0).toUpperCase();
      }
      if (address.surname) {
        letters += address.surname.charAt(0).toUpperCase();
      }
      return letters;
    },
    paginateItems() {
      this.firstNumber = this.perPage * (this.page - 1) + 1;
      const itemsOnPage = this.addresses.length;
      this.secondNumber = (this.page - 1) * this.perPage + itemsOnPage;
    },
    async getAddresses(params = {}, next = false, uselike = false, updated) {
      try {
        params.offset = updated ? 0 : (this.page - 1) * this.perPage;
        params.limit = updated ? this.largeLimit : this.perPage;
        this.loading = true;
        const newItems = await addressService.getAddressesList({
          ...params,
          order: 'DESC',
          orderby: 'time_created',
          uselike: uselike,
        });
        // console.log(params)
        if (next) {
          this.addresses.push(...newItems);
        } else {
          this.addresses = newItems;
        }

        this.addresses = uniqueByUUID(this.addresses);
        this.loadMoreDisabled = newItems.length < this.perPage;
        this.setLoading(false);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    },
    async previousPage() {
      if (this.page !== 1) {
        this.page -= 1;
        this.setLoading(true);
        await this.getAddresses({}, true);
      }
    },
    async nextPage() {
      this.page += 1;
      // this.setLoading(true);
      this.isLoadingFilter = true;
      // console.log(this.inputFilter)
      await this.getAddresses(this.inputFilter, true, true);
      this.isLoadingFilter = false;
    },
  },

  async mounted() {
    this.setLoading(true);
    await this.getAddresses();
    EventBus.$on('address-added', async () => {
      this.page = 1;
      this.setLoading(true);
      await this.getAddresses();
    });
    EventBus.$on('address-updated', async () => {
      this.setLoading(true);
      await this.getAddresses(this.inputFilter, false, true, true);
    });
  },
};
</script>

<style lang="scss">
.pagination-adress-items {
  &-header,
  &-row {
    display: flex;
    > div {
      &:nth-child(1) {
        width: 5%;
        display: flex;
        justify-content: center;
      }
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        width: 18%;
      }
      &:nth-child(5) {
        width: 20%;
      }
      &:nth-child(6) {
        width: 10%;
      }
      &:nth-child(7) {
        width: 10%;
      }
      &:nth-child(8) {
        width: 5%;
      }
    }
    &.active {
      background: rgba(#f3ffc7, 0.5) !important;
      font-weight: 600;
      &:hover {
        background: rgba(#f3ffc7, 0.5) !important;
      }
    }
  }
}

.name-circle {
  width: 44px;
  height: 44px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 18px;
  color: white;
  border-radius: 50%;
  background: #05a6a6;
}
.pagination-adress-items {
  &-header {
    background: transparent;

    .td {
      padding: 4px;
      text-align: center;
      input {
        padding: 4px;
        &:disabled {
          background-color: #eeeded;
        }
      }
      span {
        font-family: Roboto;
        margin-bottom: 15px;
        display: block;
        color: #676767;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
      }
    }
  }
}
</style>
