<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
    :persistent="isChanged"
    @click:outside="showConfirmDialog"
    content-class="cross__dialog"
    scrollable
  >
    <VCard :height="$vuetify.breakpoint.smAndDown ? '500' : ''">
      <div class="cross__inner">
        <VBtn fab small color="white" elevation="0" @click="showConfirmDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <VCardTitle>
        <span class="headline" :class="{ 'text-body-1': $vuetify.breakpoint.xs }">{{
          $t("addresses.createAdressBtn")
        }}</span>
      </VCardTitle>
      <VCardText>
        <VContainer class="py-0">
          <div class="text-center" v-if="loading">
            <VProgressCircular :size="50" color="primary" indeterminate></VProgressCircular>
          </div>
          <div class="" v-else>
            <span
              class="text-h6 text--black d-block mb-1"
              :class="{ 'text-body-1': $vuetify.breakpoint.xs }"
            >
              {{ $t("addresses.contacts") }}
            </span>
            <VRow>
              <VCol cols="12" md="6" sm="12" class="">
                <VTextField
                  outlined
                  dense
                  :label="$t('form.name')"
                  required
                  v-model="address.name"
                  :error-messages="nameErrors"
                  @paste="noEngTextPasted($event)"
                  :hint="onEngText ? '' : $t('form.hint.NameNoEngText')"
                  hide-details="auto"
                  class="br-10"
                  @input="noEngText($event,false)"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" sm="12" class="">
                <VTextField
                  outlined
                  dense
                  :label="$t('form.surname')"
                  required
                  v-model="address.surname"
                  :error-messages="surnameErrors"
                  @input="noEngText($event,false)"
                  @paste="noEngTextPasted($event)"
                  :hint="onEngText ? '' : $t('form.hint.SurnameNoEngText')"
                  hide-details="auto"
                  class="br-10"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" sm="12" class="my-0 py-0">
                <VTextField
                  outlined
                  dense
                  :label="$t('form.email')"
                  required
                  v-model="address.email"
                  :error-messages="emailErrors"
                  class="br-10"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="6" sm="12" class="my-0 py-0">
                <VTextField
                  class="inputPhone br-10"
                  outlined
                  dense
                  :label="$t('form.phone_number')"
                  required
                  v-model="address.phone"
                  :error-messages="phoneErrors"
                  prefix="+38"
                  v-on:keypress="onlyNumber($event)"
                  @paste="noEngTextPasted($event, true)"
                  :maxLength="20"
                >
                </VTextField>
              </VCol>
            </VRow>
            <VDivider class="my-1" />
            <span
              class="text-h6 text--black d-block"
              :class="{ 'text-body-1': $vuetify.breakpoint.xs }"
              >{{ $t("addresses.adressesTitle") }}</span
            >
            <VRow class="my-0 py-0">
              <!--              <VCol cols="12" md="8" class="">-->
              <!--                <VAutocomplete-->
              <!--                  :items="cities"-->
              <!--                  outlined-->
              <!--                  dense-->
              <!--                  class="br-10"-->
              <!--                  label="Область"-->
              <!--                  item-text="AreaDescription"-->
              <!--                  item-value="AreaRef"-->
              <!--                  hide-details-->
              <!--                  @change="onSelectRegion"-->
              <!--                  v-model="address.region"-->
              <!--                  :error-messages="regionErrors"-->
              <!--                />-->
              <!--              </VCol>-->
              <VCol cols="12" lg="6" md="6" sm="12" class="">
                <VAutocomplete
                  :items="activeCities"
                  :loading="regionLoading"
                  outlined
                  dense
                  class="br-10"
                  :label="$t('form.city')"
                  item-text="Description"
                  item-value="Ref"
                  :hide-details="!cityErrors.length"
                  @change="onSelectCity(address.city, 'createAdress')"
                  v-model="address.city"
                  :error-messages="cityErrors"
                />
              </VCol>
              <VCol cols="12" lg="6" md="6" sm="12" class="">
                <VAutocomplete
                  :items="warehouses"
                  outlined
                  dense
                  class="br-10"
                  :label="$t('form.department')"
                  item-text="Description"
                  item-value="Ref"
                  @change="onSelectWarehouse('createAdress')"
                  v-model="address.warehouse"
                  :disabled="!address.city"
                  :hide-details="!warehouseErrors.length"
                  :error-messages="warehouseErrors"
                />
              </VCol>
            </VRow>
          </div>
        </VContainer>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0 container">
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              class="text-white text-transform-none br-10 w-100"
              @click="onClose"
              block
              dark
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6" class="my-0 py-0">
            <VBtn
              class="success-bg text-white text-transform-none br-10 w-100"
              block
              @click="onCreate"
              :disabled="!isChanged"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.create") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </VDialog>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import * as mutations from "@/store/modules/novaPochta/types/mutations";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import language from "../../mixins/language";
import notifications from "../../mixins/notifications";
import addressService from "../../services/request/address/addressService";
import EventBus from "../../events/EventBus";
import novaPochta from "../../mixins/novaPochta";
import phoneMask from "../../validators/phoneMask";
import { noEngText, onlyNumber, noEngTextPasted } from "../../mixins/helpers";
import noEngTextValidator from "@/validators/noEngText";

export default {
  name: "CreateAddressDialog",
  props: ["visible"],
  validations: {
    address: {
      name: { required, noEngTextValidator },
      surname: { required, noEngTextValidator },
      phone: { required, phoneMask },
      // region: { required },
      city: { required },
      email: { email },
      warehouse: { required }
    }
  },
  components: {
    ConfirmLeftDialog
  },
  data: () => ({
    onEngText: true,
    loading: false,
    isChanged: false,
    visibleConfirm: false,
    address: {
      name: "",
      surname: "",
      phone: "",
      email: "",

      // region: '',
      city: "",
      street: "",
      house_number: "",
      flat_number: "",
      description: "",
      warehouse: ""
    }
  }),
  watch: {
    address: {
      deep: true,
      handler() {
        const values = Object.values(this.address);
        if (values.filter(item => item).length) {
          this.isChanged = true;
        } else {
          this.isChanged = false;
        }
      }
    }
  },
  mixins: [language, validationMixin, notifications, novaPochta],
  async mounted() {
    try {
      await this.fetchNovaPochtaCities();
      this.activeCities = this.cities;
    } catch (e) {
      this.setErrorNotification(e);
    }
  },
  methods: {
    noEngText,
    onlyNumber,
    noEngTextPasted,
    onClose() {
      this.address = [];
      this.$store.commit(`novaPochta/${mutations.DELAY_WAREHOUSES}`);
      this.$v.$reset();
      this.visibility = false;
    },
    showConfirmDialog() {
      if (this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    clearAddress() {
      Object.keys(this.address).forEach(key => {
        this.address[key] = "";
      });
    },
    async onCreate() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.address.name);
          formData.append("surname", this.address.surname);
          if (this.address.email) {
            formData.append("email", this.address.email);
          }
          formData.append("phone", `+38${this.address.phone}`);
          formData.append("uuid_ref_warehouse", this.address.warehouse);
          formData.append("uuid_ref_city", this.address.city);
          formData.append("name_city", this.address.name_city);
          formData.append("name_warehouse", this.address.name_warehouse);
          await addressService.createAddress(formData);
          EventBus.$emit("address-added");
          this.loading = false;
          this.visibility = false;
          this.$v.$reset();
          this.setSuccessNotification(this.language.ADDRESS_CREATED);
          this.clearAddress();
        } catch (e) {
          this.setErrorNotification(e);
          this.loading = false;
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.address.name.$dirty) {
        return errors;
      }
      if (!this.$v.address.name.noEngTextValidator) {
        errors.push(this.$t("form.hint.NameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.name.required && errors.push(this.$t("form.errors.NameRequired"));
      return errors;
    },
    surnameErrors() {
      const errors = [];
      if (!this.$v.address.surname.$dirty) {
        return errors;
      }
      if (!this.$v.address.surname.noEngTextValidator) {
        errors.push(this.$t("form.hint.SurnameNoEngText"));
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.surname.required && errors.push(this.$t("form.errors.SurnameRequired"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.address.phone.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.phone.required && errors.push(this.$t("form.errors.PhoneNumberRequired"));
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.phone.phoneMask && errors.push(this.$t("form.errors.PhoneNumberMustBeLike"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.address.email.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.email.email && errors.push(this.$t("form.errors.EmailMustBeValid"));
      return errors;
    },
    regionErrors() {
      const errors = [];
      if (!this.$v.address.region.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.region.required && errors.push(this.$t("form.errors.RegionRequired"));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.address.city.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.city.required && errors.push(this.$t("form.errors.CityRequired"));
      return errors;
    },
    warehouseErrors() {
      const errors = [];
      if (!this.$v.address.warehouse.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.address.warehouse.required && errors.push(this.$t("form.errors.DepartmentRequired"));
      return errors;
    }
  }
};
</script>

<style>
/* .v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__append-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined
.v-input__append-outer {
  margin-top: 12px !important;
}

.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(.v-text-field--solo) .v-input__append-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-outer {
  margin-top: 12px !important;
} */
</style>
